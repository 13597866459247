<template>
  <MDBFooter :text="['center', 'lg-start']">

    <div class="text-end p-3" style="background-color: rgba(0, 0, 0, 0.2);">

      <a href="https://github.com/damanic" class="icon-link">
        <MDBIcon icon="github" iconStyle="fab" size="lg" />
      </a>

      <a href="#" @click.prevent="contactMatt" class="icon-link">
        <MDBIcon icon="at" iconStyle="fas" size="lg" />
      </a>

      <a href="#tips" @click="bounceTipsCard"  class="icon-link">
        <MDBIcon icon="btc" iconStyle="fab" size="lg"/>
      </a>
    </div>

  </MDBFooter>

</template>


<script>
  import { MDBFooter,MDBIcon } from 'mdb-vue-ui-kit';

  export default {
    components: {
    MDBFooter,
      MDBIcon
    },
    data () {
      return {
        btcClicks: 0
      }
    },
    methods: {
      contactMatt() {
        let y = new Date().getFullYear();
        var email = y + '@mjman.net';
        alert('Please feel free to contact me via: ' + email);
        document.location.href = "mailto:" + email;
      },
      bounceTipsCard(){
        let animations = ['animate__tada','animate__bounce','animate__flash', 'animate__pulse', 'animate__rubberBand','animate__shakeY','animate__swing','animate__jello'];
        let card = document.getElementById("tips-card");
        if(card){
          card.classList.remove('animate__animated');
          animations.forEach(className => {
            card.classList.remove(className);
          } );
          card.classList.add('animate__animated', animations[this.btcClicks]);
          this.btcClicks++;
          if(this.btcClicks > 7){
            this.btcClicks = 0;
          }
        }
      }
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

footer{
  padding-top:4rem;
  .icon-link{
    color:black;
    margin-right:1rem;
  }
}
</style>