<template>
    <form method="POST"  @submit.prevent="onBTCPayFormSubmit"  action="https://btc.mjman.net/api/v1/invoices"  id="btcpay-form" class="btcpay-form">

      <input type="hidden" name="storeId" value="7BMo5UJmNN7sQt738dCWwrQ9agRHwwWEPrtwT2KW2PkZ" />
      <input type="hidden" name="jsonResponse" value="true" />


      <div class="mb-2">
        <MDBInput label="optional: message or payment ID" v-model="orderId" name="orderId" maxlength="100"/>
      </div>

          <MDBInput
              inputGroup
              :formOutline="true"
              wrapperClass="mb-3"
              v-model="inputPrice"
              aria-label="Currency Toggle"
              @input.prevent="onBTCPayInput"
              @focusin="onBTCPayInputFocus"
              id="btcpay-input-price"
              ref="inputPrice"
              name="price"
              type="0"
              step="1"
              class="text-end">


            <template #prepend>
              <MDBDropdown btnGroup  v-model="currencyDropdown" data-bs-auto-close="true">
                <MDBDropdownToggle  @click="currencyDropdown = !currencyDropdown" id="currencyToggleButton">{{ currencyFractionalCode }}</MDBDropdownToggle>
                <MDBDropdownMenu :animation="true" aria-labelledby="dropdownMenuButton">
                  <MDBDropdownItem href="#" @click.prevent="setCurrencyId('STSH')">SATOSHI</MDBDropdownItem>
                  <MDBDropdownItem href="#" @click.prevent="setCurrencyId('BTC')">BTC</MDBDropdownItem>
                  <MDBDropdownItem href="#" @click.prevent="setCurrencyId('AUD')">AUD</MDBDropdownItem>
                  <MDBDropdownItem href="#" @click.prevent="setCurrencyId('USD')">USD</MDBDropdownItem>
                  <MDBDropdownItem href="#" @click.prevent="setCurrencyId('GBP')">GBP</MDBDropdownItem>
                  <MDBDropdownItem href="#" @click.prevent="setCurrencyId('EUR')">EUR</MDBDropdownItem>
                </MDBDropdownMenu>
              </MDBDropdown>
            </template>

              <MDBBtn class="btc-pay-send" type="submit" name="submit" color="warning">Send</MDBBtn>


          </MDBInput>


      <input type="hidden" v-model="currencyCode" name="currency"/>
      <input type="hidden" v-model="currencyFractionalCode" />
    </form>

</template>

<script>
import { ref } from 'vue';
import {
  // MDBContainer,
  // MDBCol,
  // MDBRow,
  MDBBtn,
  MDBInput,
  MDBDropdown,
  MDBDropdownMenu,
  MDBDropdownToggle,
  MDBDropdownItem,
} from 'mdb-vue-ui-kit';

export default {
  name: "BtcPay",
  data() {
    return {
      inputPrice: 10000,
      currencyCode: 'BTC',
      currencyFractionalCode: 'SATOSHI'
    }
  },
  components: {
    // MDBContainer,
    // MDBCol,
    // MDBRow,
    MDBBtn,
    MDBInput,
    MDBDropdown,
    MDBDropdownMenu,
    MDBDropdownToggle,
    MDBDropdownItem,
  },
  methods: {
    satsToBtc(sats){
      return sats / 100000000;
    },
    btcToSats(btc){
      return btc * 100000000;
    },
    isSatoshi(){
      if(this.currencyCode == 'BTC' && this.currencyFractionalCode == 'SATOSHI'){
        return true;
      }
      return false;
    },
    getDecimalPlaces(){
      let places = 2;
      if(this.currencyCode == 'BTC'){
        places = 8;
        if( this.isSatoshi()){
          places = 0;
        }
      }
      return places;
    },
    getDefaultAmount(){
      let amount = 10;
      if(this.currencyCode == 'BTC'){
        amount = 0.0001;
        if( this.isSatoshi()){
          amount = this.btcToSats(amount);
        }
      }
      return amount;
    },
    getMinimumAmount(){
      let amount = 0.01;
      if(this.currencyCode == 'BTC'){
        amount = 0.00000001;
        if( this.isSatoshi()){
          amount = this.btcToSats(amount);
        }
      }
      return amount;
    },
    onBTCPayFormSubmit(event) {
      let formData = new FormData( event.target );

      if(formData.get('price') < this.getMinimumAmount()){
        alert('Payment must be at least '+this.getMinimumAmount().toFixed(this.getDecimalPlaces() )+' '+this.currencyFractionalCode);
      }

      //Convert satoshis into BTC
      if(this.isSatoshi()){
        let satoshiPrice = parseFloat(formData.get('price'));
        if(satoshiPrice){
          formData.set('price', this.satsToBtc(satoshiPrice));
        }
      }

      var xhttp = new XMLHttpRequest();
      xhttp.onreadystatechange = function() {
        if (this.readyState == 4 && this.status == 200) {
          if(this.status == 200 && this.responseText){
            var response = JSON.parse(this.responseText);
            window.btcpay.showInvoice(response.invoiceId);
          }
        }
      };
      xhttp.open("POST", event.target.getAttribute('action'), true);
      xhttp.send(formData);
    },
    onBTCPayInput(event) {
      let value = event.target.value;
      value = isNaN(value) ? this.getDefaultAmount() : value;
      if(this.isSatoshi()){ //no fractions
        value = Math.abs(value);
      }
      if (value < 0) {
        value = this.getDefaultAmount();
      }
      document.querySelector('#btcpay-input-price').value = value;
    },
    onBTCPayInputFocus(event){
      event.target.select();
    },
    setCurrencyId(currencyID){
       if(currencyID == 'STSH'){
         this.currencyFractionalCode = 'SATOSHI';
         this.currencyCode = 'BTC';
       } else {
         this.currencyFractionalCode = currencyID;
         this.currencyCode = currencyID;
       }
      this.inputPrice = this.getDefaultAmount();
      let el = document.getElementById('btcpay-input-price');
      el.click();
      setTimeout(() => {  el.select() }, 100);
    }
  },
  mounted(){

  },
  setup() {
    const orderId = ref(null);
    const currencyDropdown = ref(false);
    const dropdown2 = ref(false);
    return {
      orderId,
      currencyDropdown,
      dropdown2
    };
  },
};

if(!window.btcpay){
  var head = document.getElementsByTagName('head')[0];
  var script = document.createElement('script');
  script.src='https://btc.mjman.net/modal/btcpay.js';
  script.type = 'text/javascript';
  head.append(script);
}



</script>

<style lang="scss" >


.btcpay-form{

  .btn{
    color: #161616;
  }

  .dropdown-toggle {
    border-color: #4f4f4f;
    background-color: #ccc;
  }

  .form-control:focus ~ .form-notch div {
    border-color:  #ccc !important;
    border-width: 1px !important;
  }

  .btc-pay-send{
    box-shadow: none !important;
    border-radius: 0 .25rem .25rem 0 !important;
    background-color:#ffc83d;
  }

  .btc-pay-menu--blue{
    max-width:130px;
    background-color: transparent;
    border-radius: 0;
    li{
      border-radius: 0;
      background-color: #022f85;
      max-width:130px;
      a {
        color: white;
      }
    }
  }



}

</style>
