<template>
  <div class="slanted">
    <div class="particle-network-animation"></div>
  </div>
  <a id="particle-network-end"></a>
</template>

<script>
import { onMounted } from "vue";
import {  } from 'mdb-vue-ui-kit';
import { PNA } from "@/assets//pna.js";
export default {
  name: "ParticleNetwork",
  components: {},
  setup() {
    onMounted(() => {
      let animation = new PNA();
      let container = document.getElementsByClassName('particle-network-animation')[0];
      if(animation && container) {
        animation.init(container);
      }
    });
  }
};
</script>

<style lang="scss" scoped>
@use "sass:math";
.particle-network-animation {
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  height: 100vh;
  background-color: #171717;
  z-index: 2;

  &::before {
    z-index: 1;
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: url('/img/space_bg.jpg');
    background-position: center center;
    background-size: cover;
    opacity: 0.05;
    transform-origin: left bottom;
    transform: skewY(3deg);
    animation: fadein 15s;
    @keyframes fadein {
      from { opacity: 0; }
      to   { opacity: 0.05; }
    }
  }
  canvas {
    z-index: 2;
    position:relative;
  }
}
.glow {
  z-index: -2;
  position: absolute;
  top: 50%;
  left: 50%;
  background-image: radial-gradient(circle closest-side, rgba(255, 255, 255, 0.025), transparent);
}
$duration: 25s;
.glow-1 {
  width: 150vw;
  height: 150vh;
  margin-top: -75vh;
  margin-left: -75vw;
  animation: glow-1-move $duration linear infinite both;
}
@keyframes glow-1-move {
  from {
    transform: translate(-100%, 100%);
  }
  to {
    transform: translate(100%, -100%);
  }
}
.glow-2 {
  width: 100vw;
  height: 100vh;
  margin-top: -50vh;
  margin-left: -50vw;
  animation: glow-2-move $duration linear math.div($duration , 3) infinite both;
}
@keyframes glow-2-move {
  from {
    transform: translate(-100%, 0%);
  }
  to {
    transform: translate(100%, 100%);
  }
}
.glow-3 {
  width: 120vw;
  height: 120vh;
  margin-top: -60vh;
  margin-left: -60vw;
  animation: glow-3-move $duration linear math.div($duration , 3) * 2 infinite both;
}
@keyframes glow-3-move {
  from {
    transform: translate(100%, 100%);
  }
  to {
    transform: translate(0%, -100%);
  }
}
.slanted:before, .slanted:after {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  background-color:#171717;
  z-index: 1;
  top: 0;
  transform-origin: left top;
  transform: skewY(-4deg);
}
.slanted:after {
  bottom: 0;
  transform-origin: left bottom;
  transform: skewY(4deg);
  background-color:#171717;
}
</style>