<template>
  <Header />
  <ParticleNetwork />
  <a id="engage"></a>
  <WelcomeTron />
  <Footer />
</template>

<script>
// @ is an alias to /src
import Header from "@/components/Header";
import ParticleNetwork from "@/components/ParticleNetwork";
import WelcomeTron from "@/components/WelcomeTron";
// import XpList from "@/components/XpList";
import Footer from "@/components/Footer";
import "animate.css"

export default {
  name: "Home",
  components: {
    // XpList,
    Header,
    WelcomeTron,
    ParticleNetwork,
    Footer
  }
};
</script>