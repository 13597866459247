<template>
  <div class="container">
    <h1>You have landed on the dark side of the moon  <span>🌚</span></h1>
    <p>There is not much to see here.</p>
    <p><small>[ {{ currentRoutePath }} ]</small></p>
    <pre>
      {{currentRouteInfo}}
    </pre>
    <a href="/" title="@ warp speed">🚀 launch</a>
</div>
</template>

<script>
import { useRouter } from 'vue-router';
export default  {
  name: "NotFound",
  components: { },
  computed: {
    currentRoutePath() {
      return useRouter().currentRoute.value.fullPath;
    },
    currentRouteInfo() {
      return useRouter().currentRoute;
    }
  }
};
</script>

<style scoped>
::-moz-selection {
  background: #b3d4fc;
  text-shadow: none;
}

::selection {
  background: #b3d4fc;
  text-shadow: none;
}

html {
  padding: 30px 10px;
  font-size: 20px;
  line-height: 1.4;
  color: #737373;
  background: #f0f0f0;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

html,
input {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

body {
  max-width: 500px;
  _width: 500px;
  padding: 30px 20px 50px;
  border: 1px solid #b3b3b3;
  border-radius: 4px;
  margin: 0 auto;
  box-shadow: 0 1px 10px #a7a7a7, inset 0 1px 0 #fff;
  background: #fcfcfc;
}

.container{
  padding:2rem;
}


h1 span {
  color: #bbb;
}

h3 {
  margin: 1.5em 0 0.5em;
}

p {
  margin: 1em 0;
}

ul {
  padding: 0 0 0 40px;
  margin: 1em 0;
}


</style>
