<template>

  <div class="pt-5 bg-light" >
    <div class="">
    <MDBContainer>
      <MDBRow>
        <MDBCol md="6" class="mb-4">
          <h1 class="mb-0">
            <small>full stack</small> <br/>
            DEVELOPER
          </h1>
          <p class="mb-3">web applications // interfaces </p>
          <a class="logo" id="logo_github" target="_blank" href="https://github.com/damanic">GitHub public repositories and code contributions</a>
        </MDBCol>
        <MDBCol md="6">
          <a id="tips"></a>
          <MDBCard id="tips-card">
            <MDBCardBody>
              <MDBCardTitle>
                <h4>payments  <MDBIcon icon="equals" iconStyle="fas"  /> tips <MDBIcon icon="equals" iconStyle="fas" /> contributions</h4>
                <p><small>Bitcoin &#9889;</small></p>
              </MDBCardTitle>
              <MDBCardText>

                <BtcPay />
                <div class="fiat">
                  <p class="mb-0"><small>deprecated method <MDBIcon icon="arrow-right" iconStyle="fas"  /> &nbsp;</small>
                    <a href="https://www.paypal.com/paypalme/mjmandev" class="icon-link">
                      <MDBIcon icon="paypal" iconStyle="fab" size="lg"/>
                    </a>
                  </p>
                </div>
              </MDBCardText>
            </MDBCardBody>
          </MDBCard>


        </MDBCol>
      </MDBRow>
    </MDBContainer>
    </div>
  </div>

</template>

<script>
import {
  MDBContainer,
  MDBCol,
  MDBRow,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBIcon
} from 'mdb-vue-ui-kit';
import BtcPay from "@/components/BtcPay";

export default {
  name: "WelcomeTron",
  components: {
    MDBContainer,
    MDBCol,
    MDBRow,
    BtcPay,
    MDBCard,
    MDBCardBody,
    MDBCardTitle,
    MDBCardText,
    MDBIcon
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  .logo{
    background-position: 0;
    background-repeat: no-repeat;
    display: block;
    margin: 2em 0 2em 0;
    text-indent: -9999999px;
    opacity: 0.99;
  }
  #logo_github {
    background-image: url('/img/github.png');
    background-size: auto 30px;
    max-width:200px;
    height: 30px;
    margin:0;
  }
  .card{
    z-index:5;
  }
  .slanted:before, .slanted:after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -1;
    top: 0;
  }
  .slanted:after {
    left:-15%;
    bottom: 0;
    transform-origin: right bottom;
    transform: skewY(-2deg);
    background:#fbfbfb;
  }
  .fiat{
    text-align: right;
  }
</style>