<template>
  <header class="fixed-top">
    <MDBContainer>
    <!-- Navbar -->
      <MDBNavbar dark container position="sticky">
          <MDBNavbarBrand href="#engage"><img src="https://mjman.net/img/logo_connected.png">
          </MDBNavbarBrand>
        <a href="#engage" class="hint visible">
          <div class="chevron"></div>
          <div class="chevron"></div>
          <div class="chevron"></div>
        </a>
          <MDBNavbarNav right class="mb-2 mb-lg-0">
            <MDBNavbarItem to="#"  @click.prevent="contactMatt">
              Matt Manning <br/>
              <small>   <MDBIcon icon="at" /> contact</small>
            </MDBNavbarItem>
          </MDBNavbarNav>
      </MDBNavbar>
      <!-- Navbar -->
    </MDBContainer>
  </header>
</template>

<script>
import {
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarNav,
  MDBNavbarItem,
  MDBContainer,
  MDBIcon } from 'mdb-vue-ui-kit';

export default {
  components: {
    MDBNavbar,
    MDBNavbarBrand,
    MDBNavbarNav,
    MDBNavbarItem,
    MDBContainer,
    MDBIcon
  },
  methods: {
    contactMatt() {
      let y = new Date().getFullYear();
      var email = y+'@mjman.net';
      alert('Please feel free to contact me via: '+email);
      document.location.href = "mailto:"+email;
    },
    trackHeader() {
      let header = document.getElementsByTagName("header")[0];
      let hint = document.getElementsByClassName("hint")[0];
      document.addEventListener('scroll', function () {
        if (header) {
          let position = window.scrollY;
          if (position > 100) {
            header.classList.add("engaged");
          } else {
            header.classList.remove("engaged");
          }
          if (position > 5) {
            hint.classList.remove("visible");
          }
        }
      });
    }
  },
  mounted(){
    this.trackHeader();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

header {
  transition: background .5s ease-out;
  background-color: rgba(23, 23, 23, 0.7);
  &.engaged{
    background-color: rgba(23, 23, 23, 1);
  }
}
img{
  filter:invert(100%);
  padding:0.2rem;
  height:60px;
}

// (your rules - which can now include sass rules)
.navbar{
  &-nav{
    text-align: right;
    a {
      color:whitesmoke;
    }
  }
  &-brand{
    margin-right:2px;
  }
}

.hint {
  margin-top:-50px;
  opacity:0;
  &.visible{
    opacity:0.7;
    animation: fadein 120s;
  }

  .chevron {
    position: absolute;
    width: 20px;
    height: 4px;
    opacity: 0;
    transform: scale3d(0.5, 0.5, 0.5);
    animation: move 3s ease-out infinite;
  }
  .chevron:first-child {
    animation: move 3s ease-out 1s infinite;
  }
  .chevron:nth-child(2) {
    animation: move 3s ease-out 2s infinite;
  }
  .chevron:before,
  .chevron:after {
    content: ' ';
    position: absolute;
    top: 0;
    height: 100%;
    width: 51%;
    background: #fff;
  }
  .chevron:before {
    left: 0;
    transform: skew(0deg, 30deg);
  }
  .chevron:after {
    right: 0;
    width: 50%;
    transform: skew(0deg, -30deg);
  }

  @keyframes move {
    25% {
      opacity: 1;
    }
    33% {
      opacity: 1;
      transform: translateY(39px);
    }
    67% {
      opacity: 1;
      transform: translateY(46px);
    }
    100% {
      opacity: 0;
      transform: translateY(80px) scale3d(0.5, 0.5, 0.5);
    }
  }
  @keyframes pulse {
    to {
      opacity: 1;
    }
  }
  @keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 0.7; }
  }
}
</style>
